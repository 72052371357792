import { ChallengeType } from "./ChallengeType";

let timeframe1Array: Array<ChallengeType> = []; // Make sure to load in ordered form, it will flip in the results so newest is at the top
timeframe1Array.push({
  title: "Develop a Retro Game",
  number: 1,
  description: "Recreate a classic retro game in JS, Java, Python, or C#. It should have a visible display to play from. Suggestions are: Tetris, Asteroids, Pac-man, Space Invaders, Snake, Centipede, Missile Command, Mario, or any more you can think of. ",
  codyLink: "https://github.com/Cody-Howell/SC2024-RetroGame",
  codyStars: 4,
  taftLink: "",
  taftStars: 0, 
  jesseLink: "https://github.com/eisbaerBorealis/personal/tree/master/learning/summer-challenges/ch1-tetris",
  jesseStars: 3, 
  justinLink: "",
  justinStars: 0, 
  jimLink: "",
  jimStars: 0, 
  starDates: ["6/25/2024", "6/27/2024", "6/30/2024", "No Date Set"],
  dueDate: "7/1/2024"
});
timeframe1Array.push({
  title: "Develop a Productivity App",
  number: 2,
  description: "Build a productivity tool for yourself! Some examples are: timers, scheduling, project planning, checklist, etc. It can be built in the web or as an app.",
  codyLink: "https://github.com/Cody-Howell/SC2024-ProductivityApp",
  codyStars: 1,
  taftLink: "",
  taftStars: 0, 
  jesseLink: "",
  jesseStars: 0, 
  justinLink: "",
  justinStars: 0, 
  jimLink: "",
  jimStars: 0, 
  starDates: ["7/3/2024", "7/10/2024", "7/15/2024", "7/31/2024"],
  dueDate: "7/3/2024"
});
timeframe1Array.push({
  title: "Create a Board Game",
  number: 3,
  description: "Make a board game! You can make something new or simply implement an existing game, such as checkers or chess, or something more exotic like Hex Chess. ",
  codyLink: "https://github.com/Cody-Howell/SC2024-BoardGame",
  codyStars: 2,
  taftLink: "",
  taftStars: 0, 
  jesseLink: "",
  jesseStars: 0, 
  justinLink: "",
  justinStars: 0, 
  jimLink: "",
  jimStars: 0, 
  starDates: ["7/17/2024", "7/24/2024", "7/31/2024", "8/15/2024"],
  dueDate: "7/31/2024"
});



export {timeframe1Array}