import { Content } from "../../_WikiData";

export const DefaultAuthClass: Content = {
  primaryCode: [
`class Auth {`,
`  static sendSignInLinkToEmail(email) {`,
`    const actionCodeSettings = {`,
`      // URL you want to redirect back to`,
`      url: 'https://example.com/finishSignIn',`,
`      handleCodeInApp: true,`,
`    };`,
``,
`    sendSignInLinkToEmail(auth, email, actionCodeSettings)`,
`      .then(() => {`,
`        // Save the email locally to complete the sign-in process`,
`        window.localStorage.setItem('emailForSignIn', email);`,
`        console.log("Completed send sign-in.");`,
`      })`,
`      .catch((error) => {`,
`        // Handle errors`,
`        console.error(error);`,
`      });`,
`  }`,
``,
`  static completeSignIn() {`,
`    if (isSignInWithEmailLink(auth, window.location.href)) {`,
`      let email = window.localStorage.getItem('emailForSignIn');`,
`      if (!email) {`,
`        // User needs to provide email again`,
`        email = window.prompt('Please provide your email for confirmation');`,
`      }`,
``,
`      signInWithEmailLink(auth, email, window.location.href)`,
`        .then(async (result) => {`,
`          // Signed in successfully`,
`          window.localStorage.removeItem('emailForSignIn');`,
``,
`          // Some custom logic here for my database`,
`          let email = result.user.email;`,
`          let uid = result.user.uid;`,
`          const docRef = doc(db, "users", uid);`,
`          const docSnap = await getDoc(docRef);`,
`          if (docSnap.data() !== undefined) {`,
`            console.log('User document already exists:', docSnap.data());`,
`          } else {`,
`            console.log("Document doesn't exist");`,
`            // The document does not exist, create a new one`,
`            let sl = 1;`,
`            let obj = {};`,
`            obj["email"] = email;`,
`            obj["sl"] = sl;`,
`            await setDoc(doc(db, "users", uid), obj);`,
`          }`,
`        })`,
`        .catch((error) => {`,
`          console.error('Error checking user document:', error);`,
`        });`,
`    }`,
`  }`,
``,
`  static async getUserInformation(uid) {`,
`    const docRef = doc(db, "users", uid);`,
`    const docSnap = await getDoc(docRef);`,
`    return docSnap.data();`,
`  }`,
``,
`  static getAuthInformation() {`,
`    return auth.currentUser;`,
`  }`,
``,
`  static signOutUser() {`,
`    signOut(auth);`,
`    window.location.reload();`,
`  }`,
`}`,
  ],
  paragraphs: [],
  seeSimilar: [
    { path: "/react/(lb)-re-auth-on-load-in", visibleName: "Set State in App" }
  ],
  externalLinks: []
}